@use '@angular/material' as mat;
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@include mat.core();

$my-primary: mat.define-palette(mat.$light-blue-palette);
$my-accent: mat.define-palette(mat.$light-blue-palette);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-app-warn,
 ),
 typography: mat.define-typography-config(),
 density: -2,
));

@include mat.all-component-themes($my-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;}
